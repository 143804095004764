/* Contact.css */
.contact {
    padding: 50px;
    background-color: var(--light-bg-color);
  }
  
  .contact-info {
    margin-bottom: 40px;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
  }
  
  input, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .success-msg {
    color: var(--primary-color);
    font-size: 18px;
    margin-top: 20px;
  }
  .team-avatar1 {
    color: var(--primary-color);
    height: 180px;
    width:  520px;
  }