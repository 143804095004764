.team {
  text-align: center;
  padding: 50px 20px;
  color: #333;
  background-color: #f5f8fb;
}

.team h1 {
  font-size: 36px;
  color: #003366;
}

.team p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.team-member {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 250px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.team-title {
  font-size: 18px;
  font-weight: bold;
  color: #003366;
  margin-top: 10px;
}

.team-description {
  font-size: 14px;
  color: #666;
}

/* Responsive design */
@media (min-width: 600px) {
  .team-member {
    width: calc(50% - 20px); /* 2 items per row */
  }
}

@media (min-width: 900px) {
  .team-member {
    width: calc(33.33% - 20px); /* 3 items per row */
  }
}

@media (min-width: 1200px) {
  .team-member {
    width: calc(25% - 20px); /* 4 items per row */
  }
}
