.footer {
    background-color: #003366;
    color: white;
    text-align: center;
    padding: 4px 0;
    font-size: 14px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  