/* Colors */
:root {
  --primary-color: #003366; /* Dark Blue */
  --secondary-color: #f76c6c; /* Accent color (e.g., for buttons) */
  --light-bg-color: #f4f4f4; /* Light background */
  --text-color: #333; /* Standard text color */
}

/* Body and Layout */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--light-bg-color);
  color: var(--text-color);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding: 20px;
}

/* Navigation */
.navbar {
  background-color: var(--primary-color);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 70px;
  width: 340px;
  margin-left: -60px;
}

.nav-links {
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px; /* Larger font size */
  font-weight: bold; /* Bold font */
}

.nav-links a:hover {
  background-color: #002244; /* Slightly lighter blue */
  padding: 5px;
}

/* Buttons */
.btn-primary {
  background-color: var(--secondary-color);
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: #f43c3c;
}

.btn-submit {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btn-submit:hover {
  background-color: #002244;
}

/* Hero Section */
.hero-section {
  background: var(--primary-color);
  color: white;
  padding: 100px 20px;
  text-align: center;
}

.hero-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 24px;
  margin-bottom: 40px;
}

/* Sections */
.about-us, .mission, .services, .products {
  padding: 60px 20px;
  background-color: var(--light-bg-color);
}

.about-us img, .products img {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

h2 {
  color: var(--primary-color);
  font-size: 36px;
}

/* Team Avatar */
.team-avatar1 {
  color: var(--primary-color);
  height: 380px;
  width: 920px;
  margin-left: 300px;
}

/* Footer */
.footer {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 10px 0; /* Reduced padding for smaller height */
  font-size: 14px;
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 36px;
  }

  .hero-content p {
    font-size: 18px;
  }

  .logo {
    height: 50px;
    width: auto;
    margin-left: 0;
  }

  .team-avatar1 {
    width: 100%;
    height: auto;
    margin-left: 0;
  }

  /* Stacking navigation links vertically on small screens */
  .nav-links {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  /* Increase font size on small screens */
  .nav-links a {
    font-size: 20px;
  }
}
