.products {
  padding: 50px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-item {
  max-width: 250px;
  text-align: center;
}

.product-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.product-item h3 {
  font-size: 20px;
  margin-top: 15px;
}

.product-item p {
  font-size: 16px;
  margin-top: 10px;
}
