/* SkilledProfessionals.css */

.skilled-workers {
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 2rem;
  }
  
  .language-switcher {
    text-align: right;
    margin-bottom: 10px;
  }
  
  .language-switcher button {
    margin: 0 5px;
    padding: 8px 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  .intro {
    text-align: justify; /* Align text for better readability */
    word-wrap: break-word; /* Ensure long words break correctly */
    overflow-wrap: break-word; /* Additional word wrapping support */
    max-width: 80%; /* Adjust width for better text presentation */
    margin: 0 auto; /* Center the text block */
    line-height: 1.6; /* Improve readability */
  }
  
  
  .language-switcher button:hover {
    background: #0056b3;
  }
  
  .hero-section {
    background: whitesmoke center/cover no-repeat;
    color: white;
    padding: 3rem 2rem;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .hero-section h1 {
    font-size: 2.5rem;
  }
  
  .hero-section p {
    font-size: 1.2rem;
  }
  
  .sector-grid {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .sector-card {
    width: 300px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 1rem;
  }
  
  .sector-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .process {
    margin-top: 4rem;
    padding: 2rem;
    background: #f4f4f4;
  }
  
  .process img {
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    margin-top: 1rem;
  }
  
  .cta {
    margin-top: 3rem;
    margin-bottom: 10px;
  }
  
  .cta-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }

  .why-choose-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 240px;
  }
  
  .why-choose-list li {
    display: flex;
    align-items: center;
    gap: 8px;  /* Reduce space between ✅ and text */
    margin-bottom: 6px;  /* Reduce space between list items */
  }
  
  .why-choose-list li::before {
    content: "✅";
    font-size: 16px;
    color: #007bff;  /* Change to your primary color */
  }

  .cta {
    text-align: center;
    margin-top: 30px; /* Adjust spacing */
  }
  
  .cta-button {
    background-color: #28a745; /* Green color */
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    display: inline-block; /* Ensures it doesn't stretch */
    margin: 0 auto; /* Centers horizontally */
  }
  
  .cta-button:hover {
    background-color: #218838; /* Darker green on hover */
  }

  .process-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px; /* Adjust spacing between lines */
    margin-left: 250px;
  }
  
  .process-list li {
    display: flex;
    align-items: center;
    gap: 8px; /* Reduce space between icon and text */
    padding-left: 25px;
    position: relative;
  }
  
  .process-list li::before {
    content: "✅";
    position: absolute;
    left: 0;
    color: #007bff; /* Adjust to match theme */
    font-size: 18px;
  }

  .industry-content {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Centers all text */
    text-align: left;  /* Aligns text from the same point */
    max-width: 60%;  /* Controls width for better readability */
    margin: 0 auto;  /* Centers the container */
    margin-left: 540px;
  }
  
  .industry-content p {
    width: 100%;
    margin: 5px 0; /* Adds equal spacing between each line */
    padding-left: 20px; /* Adjust to align text neatly */
  }
  
  
  
  
  